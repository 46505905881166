const tokens = {
  "algorand-testnet": [404745510],
  algorand: [],
  voi: [],
  "voi-testnet": [
    3905802, // VRC20
    6726237, // Daves200
    6727078, // ARC200
    6726425, // IRC
  ],
};

export default tokens;
